<template>
    <div class="vertical_nav_bar mobile_hidden" >
        <BaseLink :href=link class="top" :style="{transform:rotation}">
                {{text}}
        </BaseLink>
        <div class="bot">
        </div>
    </div>
</template>

<script>
import BaseLink from './BaseLink'

export default {
    name: 'VerticalNavigationBar',

    components: {
        BaseLink  
    },

    props: {
      text: {
        type: [String],
        required: true,
        default: null,
      },
      link: {
        type: [String],
        required: true,
        default: null,
      },
      rotation: {
        type: [String],
        required: false,
        default: "rotate(90deg)",
      }
    },
}
</script>
  
<style lang="scss" scoped>

.vertical_nav_bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 60px;

    .top {
        color: inherit;
        display: flex;
        flex: 1;
        align-items: center;
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        text-align: center;
        justify-content: center;
        transform: rotate(-90deg);
        text-decoration: none;
    }

    .bot {
        display: flex;
        flex: 3;
        height: 100%;
        width: 100%;
    }
}

</style>
