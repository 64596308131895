<template>
  <div class="album">
    <div class="album__info">
        <img class="album__cover_art" :src="`${albumLink}${album.attributes.cover_art.data.attributes.url}`">
        <div class="album__text">
          <div class="album__title">
            {{album.attributes.title}}
          </div>
          <div class="album__description">
            {{album.attributes.description}}
          </div>
          <div class="album__social">
            <div class="album__social__spotify">
                Spotify
            </div>
          </div>
        </div>
    </div>
    <div class="songs">
            <SongSpotify
              v-for="song in album.attributes.songs.data"
              :key="song.id"
              :song="song"
            />
        </div>
  </div>
</template>

<script>

const {
  VUE_APP_SERVER_URL: VUE_APP_SERVER_URL,
} = process.env


import SongSpotify from '@/components/SongSpotify'

export default {
  name: 'Album',

  components: {
    SongSpotify
  },

  data () {
    return {
      albumLink: VUE_APP_SERVER_URL
    }
  },

  props: {
    album: {
      type: Object,
      default: () => ({}),
    }
  },
}
</script>

<style lang="scss" scoped>

.album
{
  flex-direction: column;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;

  }

  &__cover_art {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 250px;
    height: 250px;
    padding-right: 20px;
  }

  &__text {
    display: flex;
    flex-direction: column;
    height: 250px;
    align-items: top;

  }

  &__description {
    font-family: 'Mulish';
    font-style: normal;
    flex: 2;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    overflow: auto;
    padding-bottom: 8px;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  &__title {
    width: 58px;
    height: 30px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    padding-bottom: 8px;
  }

  &__social{
    display: flex;
    padding-top: 8px;
    &__spotify {
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 20px;
    }
  }

  .songs{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 104px;
    justify-content: space-between;
  }
}
</style>
