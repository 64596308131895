<template>
  <div class="tour-date">
    <div class="tour-date__info">
      <span class="tour-date__title">{{tour_date.attributes.title}}</span>
      <span class="tour-date__date">{{tour_date.attributes.concert_date}}</span>
      <span class="tour-date__tickets">
      <BaseLink :href=tour_date.attributes.ticket_url>
        <button class="tickets_button">Tickets</button>
      </BaseLink>
      </span>
    </div>
  </div>
</template>

<script>
import BaseLink from './BaseLink'

export default {
  name: 'TourDate',

  components: {
    BaseLink
  },

  props: {
    tour_date: {
      type: Object,
      default: () => ({}),
    }
  },
}
</script>

<style lang="scss" scoped>
.tour-date {
  display: flex;
  flex-direction: column;

  border-bottom: 1px solid white;
  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    padding-bottom: 5px;
    padding-top: 5px;
  }
  &__title {
    display: flex;
    flex: 3;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
  }

  &__date {
    display: flex;
    flex: 1;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    justify-content: right;

/* identical to box height */
  }

  &__tickets {
    display: flex;
    flex: 1;
    justify-content: right;
  }
  .tickets_button {
    padding: 16px 24px;
    gap: 10px;
    font-family: 'Mulish';
    font-style: normal;
    font-size: 22px;
    text-align: center;
    width: 126px;
    height: 60px;
    background: #FFFFFF;
    border-radius: 10px;
  }

  @media (max-width: 400px) {
    flex-direction: row;
    &__info {
      align-items: flex-start;
      flex-direction: column;
    }
  }

}
</style>
