<template>
  <div class="music">
    <PageHeader></PageHeader>
    <div class="wrapper">
      <VerticalNavigationBar text="Contact" link="contact" rotation="rotate(-90deg)"></VerticalNavigationBar>
      <div class="music__center">
        <div class="music__albums">
            <Album
              v-for="album in albums"
              :key="album.id"
              :album="album"
            />
          </div>
      </div>
      <VerticalNavigationBar text="Live" link="live"></VerticalNavigationBar>
    </div>
  </div>
</template>

<script>
import Album from '@/components/Album'
import PageHeader from '@/components/PageHeader'
import VerticalNavigationBar from '@/components/VerticalNavigationBar'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Music',

  components: {
    Album,
    PageHeader,
    VerticalNavigationBar
  },

  mounted() {
    this.fetchAlbums();
  },

  computed: {
    ...mapGetters([
      'albums'
    ])
  },
  
  methods: {
    ...mapActions([
      'fetchAlbums'
    ])
  }
}
</script>

<style lang="scss" scoped>
.music {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: $background-color;
  .wrapper{
    display: flex;
    flex-direction: row;
    height: 100%
  }

  @media (max-width: 1200px) {
    flex-direction: column;
  }
  @media (max-width: 400px) {
    flex-direction: row;
  }
  &__center {
    display: inherit;
    flex-direction: column;
    flex: 1 50%;
    margin-right: 7%;
    margin-left: 7%;
    margin-top: 7%;
    min-width: 400px;
  }

  &__albums {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
  }
}
</style>
