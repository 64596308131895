<template>
    <div class="song">
        <div class="song__spotify">
            <iframe style="border-radius:12px" :src=song.attributes.url width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
        </div>
    </div>
</template>
  
<script>

export default {
name: 'SongSpotify',

props: {
    song: {
    type: Object,
    default: () => ({}),
    }
},
}
</script>

<style lang="scss" scoped>
.song {
    flex: 50%;
    flex-direction: column;
    padding-bottom: 10px;

    &__spotify {
        margin-left: 20px;
    }
}
</style>