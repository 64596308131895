<template>
    <!-- Invalid link -->
    <span
      class="base-link disabled"
      v-bind="$attrs"
      @click="$emit('click', $event)"
      v-if="!href || disabled"
    >
      <slot />
    </span>
  
    <!-- external link -->
    <a
      class="base-link"
      :href="hrefParsed"
      target="_blank"
      @click="$emit('click', $event)"
      v-bind="$attrs"
      v-else-if="isExternalLink"
    >
      <slot />
    </a>
  
    <!-- internal link -->
    <router-link
      class="base-link"
      :to="hrefParsed"
      @click.native="$emit('click', $event)"
      v-bind="$attrs"
      v-else
    >
      <slot />
    </router-link>
  </template>
  
  <script>
  export default {
    name: 'BaseLink',
    props: {
      href: {
        type: [Object, String],
        required: false,
        default: null,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      baseUrl () {
        return location.hostname === 'localhost'
          ? process.env.VUE_APP_BASE_URL
          : location.origin
      },
      isExternalLink () {
        return typeof this.href === 'string' // if not string, vue router object
          && !this.href?.startsWith('/') // if no base, relative path
          && !this.href?.startsWith(this.baseUrl) // if not baseUrl
          && this.href?.startsWith('http') // verify absolute url
      },
      hrefParsed () {
        return typeof this.href === 'string'
        && !this.isExternalLink
        && this.href?.startsWith(this.baseUrl)
          ? this.href?.replace(this.baseUrl, '')
          : this.href
      },
    },
  }
  </script>
  
