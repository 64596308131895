<template>
  <div class="contact">
    <PageHeader></PageHeader>
    <div class="wrapper">
      <VerticalNavigationBar text="Contact" link="contact" rotation="rotate(-90deg)"></VerticalNavigationBar>

      <div class="wrapper_contact">
      <div class="contact__center">
        <body>
      <h1>Get in touch!</h1>
      <p>
      For press, bookings and other enqieries you can send us a message in the form or reach us at: solarband@gmail.com

      Don’t forget, you can always follow and reach us on social media. See you in our DM’s! 
      </p>
      <form accept-charset="UTF-8" action="https://www.formbackend.com/f/{your-identifier}" method="POST">
        <div class="form-fields">
          <label for="email">Email</label>
          <input type="email" id="email" name="email" required>
        </div>

        <div class="form-fields">
          <label for="message">Message</label>
          <textarea id="message" name="message" required></textarea>
        </div>

      <button type="submit">Send message</button>
      </form>
    </body>
  </div>
  </div>
    <VerticalNavigationBar text="Live" link="live"></VerticalNavigationBar>
  </div>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader'
import VerticalNavigationBar from '@/components/VerticalNavigationBar'

export default {
  name: 'Contact',

  components: {
    PageHeader,
    VerticalNavigationBar
  },

}
</script>

<style lang="scss" scoped>
.contact {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: $background-color;
  .wrapper{
    display: flex;
    flex-direction: row;
    height: 100%
  }
  .wrapper_contact {
    display: flex;
    flex: 1 50%;
    justify-content: center;
  }

  @media (max-width: 1200px) {
    flex-direction: column;
  }
  @media (max-width: 400px) {
    flex-direction: row;
  }
  &__center {
    display: inherit;
    flex-direction: column;
    margin-right: 7%;
    margin-left: 7%;
    margin-top: 7%;
    min-width: 400px;
    max-width: 900px;
  }
  h1 {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  .form-fields {
    margin-bottom: 1rem;
  }
  label { 
    display: block; 
    margin-bottom: 4px; 
    font-weight: bold; 
    font-size: 0.9rem;
  }
  input[type="text"], input[type="email"], textarea {
    width: 100%;
    border: 1px solid #ccc;
    font-size: 1rem;
    padding: 6px 10px;
    border-radius: 4px;
  }

  input[type="text"], input[type="message"], textarea {
    width: 100%;
    border: 1px solid #ccc;
    font-size: 1rem;
    padding: 6px 10px;
    border-radius: 4px;
    min-height: 100px;
  }
  body { display: block }
  
  button[type="submit"] {
    justify-content: right; 
    padding: 16px 24px;
    gap: 10px;
    font-family: 'Mulish';
    font-style: normal;
    font-size: 10px;
    text-align: center;
    width: 126px;
    height: 60px;
    background: #FFFFFF;
    border-radius: 10px;
  }
}
</style>
