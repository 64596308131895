<template>
    <div class="page_header">
        <BaseLink href="/" class="solar_logo">
            <img src="@/assets/icons/solar-logo-white.png" />
        </BaseLink>
    </div>
</template>

<script>
import BaseLink from './BaseLink'

export default {
    name: 'PageHeader',

    components: {
        BaseLink
    }
}
</script>
  
<style lang="scss" scoped>

.page_header{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    .solar_logo {
        display: flex;
        justify-content: center;
        height: 100%;
        width: 200px;
        padding: 24px;
    }
    img {
        max-width: 100%;
        max-height: 100%;
    }
}

</style>
